<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>
                    Каталог майнеров
                </b-card-title>
                <div class="d-flex align-items-center">
                    <b-button 
                        pill 
                        variant="light" 
                        @click="changeStateEditor"
                        :style="isEditor ? 'opacity: 0.55; cursor: not-allowed !important; pointer-events: none;' : ''"
                    >Цены</b-button>
                    <b-button 
                        pill 
                        variant="primary" 
                        :to="{ name: 'admin-miners-add' }"
                        :style="isEditor ? 'opacity: 0.55; cursor: not-allowed !important; pointer-events: none;' : ''"
                        class="ml-1"
                    > Добавить майнер </b-button>
                </div>
            </b-card-header>

            <hr v-if="isEditor" class="mt-0 mb-0">

            <transition name="fade">
                <b-card-header v-if="isEditor">
                    <b-card-title style="font-weight: 400">Редактирование</b-card-title>
                    <div class="d-flex align-items-center">
                            <b-button 
                                pill 
                                variant="light" 
                                @click="changeStateEditor"
                            > Отмена </b-button>
                            <b-button 
                                pill 
                                variant="primary" 
                                @click="updatePrices"
                                class="ml-1"
                            > Сохранить </b-button>
                    </div>
                </b-card-header>
            </transition>
            
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='8' :rows='5'>
            
            </b-skeleton-table>
            
            <b-table-simple v-else class="font-small-3 text-black" responsive>
                <b-thead>
                    <b-tr>
                        <b-td>#</b-td>
                        <b-td class="text-center" colspan="2">Название</b-td>
                        <b-td>Производитель</b-td>
                        <b-td>Страна</b-td>
                        <b-td>Хэшрейт</b-td>
                        <b-td>Поставка</b-td>
                        <b-td>Обслуживание</b-td>
                        <b-td>Стоимость</b-td>
                        <b-td></b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(miner, index) in items" :key="miner.id">
                        <b-td class="text-black font-weight-bolder">{{ miner.id }}</b-td>
                        <b-td>
                            <b-avatar 
                                rounded
                                :src="miner.medias[0].urls.thumb"
                            />
                        </b-td>
                        <b-td class="text-black font-weight-bolder">{{ miner.name }}</b-td>
                        <b-td class="text-black">{{ miner.manufacturer }}</b-td>
                        <b-td class="text-black">{{ miner.manufacturer_country }}</b-td>
                        <b-td class="text-black">{{ miner.hashrate }} TH/s</b-td>
                        <b-td class="text-black">{{ miner.delivery_period }} дней</b-td>
                        <b-td class="text-black">{{ miner.service_period }} дней</b-td>
                        <b-td v-if="!isEditor" class="text-black">{{ parseFloat(miner.price_amount).formatMoney(2," "," ") }} $</b-td>
                        <b-td v-else class="text-black"> <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            name="Стоимость"
                                            rules="required"
                                        >
                                        <b-form-input
                                          id="price_amount"
                                          type="number"
                                          placeholder="3400 USD"
                                          v-model="items[index].price_amount"
                                          @change="changed(items[index])"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group></b-td>
                        <b-td>
                            <b-dropdown
                                size="sm"
                                right
                                no-caret   
                                variant="transparent"
                            >
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" class="text-muted" size="22" />
                                </template>
                                <b-dropdown-item :to="{ name: 'admin-miners-update', params: { miner: miner } }">
                                    <feather-icon icon="EditIcon" class="text-primary" size="13" />
                                    <span class="text-primary"> Редактировать</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="preferDeleteAction(miner, index)">
                                   <feather-icon icon="TrashIcon" class="text-danger" size="13" />
                                   <span class="text-danger"> Удалить</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-card-body class="d-flex justify-content-between pt-0 pb-0">
                <span class="font-weight-bolder">Всего: {{ total_count }}</span>

                <b-pagination-nav 
                    :value="current_page" 
                    :link-gen="linkGen" 
                    :number-of-pages="last_page" 
                    use-router 
                ></b-pagination-nav>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>

    import MinerCardView from "@/components/MinerCard/"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {

        data() {
            return {
                is_loading: true,
                items: [],
                current_page: 0,
                has_more_pages: false,
                last_page: 0,
                total_count: 0,
                isEditor: false,
                prices: []
            }
        },
        methods: {
            
            preferDeleteAction( miner, index ) {
                
                this.$swal({
                    title: `Подтвердите действие`,
                    text: `Вы действительно хотите удалить ${miner.name} из каталога?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                      cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    if (result.value) {
                        this.$request.post("miners/delete", {
                            id: miner.id
                        }).then( rsp => {
                            this.items.splice( index, 1 );
                            this.$swal({
                                icon: 'success',
                                title: miner.name,
                                text: 'Майнер удален из каталога',
                                customClass: {
                                  confirmButton: 'btn btn-success',
                                },
                            });
                        });
                    }
                  });
                
                
            },
            
            linkGen( pageNum ) {
                return {
                    name: this.$route.name,
                    query: {
                        page: pageNum
                    }
                }        
            },
            
            get() {
                this.is_loading = true;

                this.$request.get("miners/list", this.$route.query ).then( rsp => {
                   this.is_loading = false;
                   this.items = rsp.items;
                   this.total_count = rsp.total_count;
                   this.current_page = rsp.current_page;
                   this.last_page = rsp.last_page;

                    if (this.prices.length > 0) {
                        this.items.forEach((item, index) => {
                            let miner = this.prices.find(l => (l.id === item.id));
                            if (miner) this.items[index] = miner
                        });
                    }
                });
            },

            changeStateEditor() {
                this.isEditor = !this.isEditor;

                if (!this.isEditor) {
                    this.prices = [];
                    this.get();
                }
            },

            updatePrices() {
                this.is_loading = true;

                this.$request.post("miners/updatePrices", {
                        miners: this.prices,
                        page: this.$route.query.page
                    }).then( rsp => {
                        
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Цены успешно изменены`,
                              icon: 'CheckCircleIcon',
                              variant: 'success',
                            }
                        });

                        this.prices = [];
                        this.isEditor = false;

                        this.is_loading = false;

                        this.items = rsp.items;
                        this.total_count = rsp.total_count;
                        this.current_page = rsp.current_page;
                        this.last_page = rsp.last_page;
                                
                    });   
            },

            changed(item) {
                this.prices = this.prices.filter(l => (l.id !== item.id));
                this.prices.push(item);
            }
        },
        components: {
            MinerCardView
        },
        watch: {
            "$route.query" : function () {
                this.get();
            }
        },
        mounted() {
            this.get();
        }

    }

</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>